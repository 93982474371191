
// Inital setup
const bodyEl = document.querySelector('body');
bodyEl.classList.add('hasJs');


// In page nav
import * as navData from '../data/navigation.json';

const inPageNavConfig = {
    navComponentSelector: '.c-local-nav',
    contentPanelsComponentSelector: '.c-content-toggle',
    linkSelector: '.c-local-nav__menu-link',
    navData: navData
};

import InPageNav from './components/in-page-nav.js';
InPageNav.init(inPageNavConfig)


function getCurrentimeForCity(timezone) {
    const currentDate = new Date();
    const currentTime = currentDate.toLocaleString("en-US", {timeZone: timezone});
    
    return currentTime;
}

const InPageNav = (function() {
    // References to UI elements
    let navComponentEl,
        contentPanelsComponentEl,
        navMenuEl;

    // Component data
    let navData;


    function enableSlidingHighlight() {
        // Create highlight bar element
        const highlightEl = document.createElement('div');
        highlightEl.classList.add('c-local-nav__menu-highlight');
        navComponentEl.appendChild(highlightEl);

        // Size and position the highlight bar
        updateSlidingHighlightPosition();

        // Basic resize handle. TODO: Improve with a debounced handler
        window.addEventListener('resize', () => {
            updateSlidingHighlightPosition()
        })
    

    }

    function updateSlidingHighlightPosition() {
        const highlightEl = document.querySelector('.c-local-nav__menu-highlight');
        const currentNavLink = navComponentEl.querySelector('.jsIsCurrent');
        const currentNavLinkRect = currentNavLink.getBoundingClientRect();

        highlightEl.style.width = `${currentNavLinkRect.width}px`;
        highlightEl.style.left = `${currentNavLinkRect.x}px`;

        // Make sure element has been revealed
        highlightEl.classList.add('jsIsVisible');
    }

    function handleClick(e) {
        e.preventDefault();
        const prevCurrentPanel = contentPanelsComponentEl.querySelector('.jsIsCurrent');

        // Highlight nav link
        const targetLink = e.target;
        const prevCurrentLink = navComponentEl.querySelector('.jsIsCurrent');
       
        if(prevCurrentLink) {
            prevCurrentLink.classList.remove('jsIsCurrent');
        }
        
        targetLink.classList.add('jsIsCurrent');
        updateSlidingHighlightPosition();

        // Toggle content panels
        const targetPanelEl = document.querySelector(targetLink.getAttribute('href'));

        prevCurrentPanel.classList.remove('jsIsCurrent');
        prevCurrentPanel.classList.add('jsIsHidden');
        targetPanelEl.classList.remove('jsIsHidden');
        targetPanelEl.classList.add('jsIsCurrent');
    }

    function buildLinks() {

        // Generate markup
        if(navData && navData.cities.length > 0) {
            const navMenuEl = document.createElement('ul');
            navMenuEl.classList.add('c-local-nav__menu');
            
            navData.cities.forEach((item, index, menuHtml) => {

                const linkEl = document.createElement('a');
                
                linkEl.setAttribute('href', `#${item.section}`);
                linkEl.classList.add(`c-local-nav__menu-link`);
                if(index === 0) {
                    linkEl.classList.add('jsIsCurrent');
                }
                linkEl.innerText = item.label;

                const listEl = document.createElement('li');
                
                listEl.classList.add(`c-local-nav__menu-item`);
                listEl.appendChild(linkEl);

                navMenuEl.appendChild(listEl);

                linkEl.addEventListener("click", handleClick);

            });

            navComponentEl.appendChild(navMenuEl);

            enableSlidingHighlight();
        }
    }

    function buildContentPanels() {
        // Generate markup
        if(navData && navData.cities.length > 0) {
            const togglePanelsEl = document.createElement('div');
            togglePanelsEl.classList.add('c-content-toggle__panels');
            
            navData.cities.forEach((item, index, menuHtml) => {
                
                // Create content for panel
                const headingEl = document.createElement('h2'); // Assume that there's an H1 on the page and this is a child heading. TODO: Change to an H3 is more appropriate.
                headingEl.classList.add('u-text-center');
                headingEl.classList.add('t-h4');
                headingEl.innerText = item.label;

                const currentTimeEl  = document.createElement('p');
                currentTimeEl.classList.add('e-current-time');
                currentTimeEl.classList.add('u-text-center');
                currentTimeEl.classList.add('t-h1');
                currentTimeEl.innerText = getCurrentimeForCity(item.timezone);

                // Render panel
                const panelEl = document.createElement('div');

                panelEl.setAttribute('id', item.section);
                panelEl.classList.add('c-content-toggle__panel');
                panelEl.appendChild(headingEl);
                panelEl.appendChild(currentTimeEl);

                // Manage initial state
                if(index > 0) {
                    panelEl.classList.add('jsIsHidden');
                }

                if(index === 0) {
                    panelEl.classList.add('jsIsCurrent');
                }
                
               
                togglePanelsEl.appendChild(panelEl);

            });

            contentPanelsComponentEl.appendChild(togglePanelsEl);
        }
    }

    

    function init(componentConfig) {
        document.addEventListener("DOMContentLoaded", function() {
            if(componentConfig && componentConfig.navData) {

                navData = componentConfig.navData;
                navComponentEl = document.querySelector(componentConfig.navComponentSelector);
                contentPanelsComponentEl = document.querySelector(componentConfig.contentPanelsComponentSelector);
                
                buildLinks();
                buildContentPanels();
            }

        }); 
        
    }

    return {
        init: init
    };
})();

export default InPageNav;
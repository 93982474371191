{
  "cities": [
    {
      "section": "cupertino",
      "label": "Cupertino",
      "timezone": "US/Pacific"
    },
    {
      "section": "new-york-city",
      "label": "New York City",
      "timezone": "US/Eastern"
    },
    {
      "section": "london",
      "label": "London",
      "timezone": "Europe/London"
    },
    {
      "section": "amsterdam",
      "label": "Amsterdam",
      "timezone": "Europe/Amsterdam"
    },
    {
      "section": "tokyo",
      "label": "Tokyo",
      "timezone": "Asia/Tokyo"
    },
    {
      "section": "hong-kong",
      "label": "Hong Kong",
      "timezone": "Asia/Hong_Kong"
    },
    {
      "section": "sydney",
      "label": "Sydney",
      "timezone": "Australia/Sydney"
    }
  ]
}
